import axios from "axios";
import { Fragment, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import FileDownload from "js-file-download";
import StripeCheckout from "react-stripe-checkout";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ViewToolkit = () => {
  const [email, setEmail] = useState();
  const [name, setName] = useState();

  let navigate = useNavigate();

  const [recommends, setRecommend] = useState([]);
  const [toolkit, setToolkit] = useState([]);
  const params = useParams();

  var viewUrl = `${process.env.REACT_APP_BASEURL_API}/view/${params.id}`;

  var subUrl = `${process.env.REACT_APP_BASEURL_API}/subscribe`;

  var subCountUrl = `${process.env.REACT_APP_BASEURL_API}/subcount/${params.id}`;

  // var downUrl = `${process.env.REACT_APP_BASEURL_API}/download`;

  var recUrl = `${process.env.REACT_APP_BASEURL_API}/viewlimit`;

  var imgUrl = `${process.env.REACT_APP_BASEURL_API}/toolkits`;

  var checkoutUrl = `${process.env.REACT_APP_BASEURL_API}/toolkit-checkout`;

  var tooltitle = `${toolkit.title}`;

  var toolprice = `${toolkit.price}`;

  var stripePubKey =
    // "pk_test_51KPRz4EVQXoztep4PmN2IEivaFBKYiIV4AZDBRjkrrJ3IzhuRVLAKFwSoTidtykkRuqY6h3gcrSbnFjEdy7CJsdn00fPhuI77Q";
    "pk_live_51KPRz4EVQXoztep47LmbIJeipkFAF04DrXOfU92dOXUyQbMR5Ti661qf6nxaY26uFPGxvJPoGBNxKgaSwRnbDy5j00bokIVlzq";

  const getToolkit = async () => {
    try {
      const response = await fetch(
        viewUrl,
        {
          method: "GET",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          // Always write this in order not to have CORS issues
          withCredentials: false,
          crossdomain: false,
          // mode: 'no-cors',
        }
      );
      const jsonData = await response.json();
      setToolkit(jsonData);
    } catch (err) {
      console.error("Cannot find Data");
    }
  };
  useEffect(() => {
    getToolkit();
  }, []);

  function validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  const subscribe = async (e) => {
    e.preventDefault(); // to prevent refreshing after submit
    if (validateEmail(email) === true) {
      if (name === undefined) {
        console.log("Name is undefined");
      } else if (name.length === 0) {
        console.log("Name is Empty");
      } else {
        try {
          const emailer = {
            email,
            name,
            tooltitle,
            toolprice,
          };
          const response = await fetch(subUrl, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(emailer),
          });
          console.log(emailer);
        } catch (err) {
          console.error(err.message);
        }

        console.log("Good to Download");
      }
    } else {
      console.log("Please input a valid email");
      alert("Please input a valid email");
    }
  };

  const countUpdator = async () => {
    const emailer = {
      tooltitle,
      toolprice,
    };
    axios
      .put(subCountUrl, emailer, {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
        },
        // Always write this in order not to have CORS issues
        withCredentials: false,
        crossdomain: false,
        mode: "no-cors",
      })
      .then((res) => {
        //   navigate(`/events`);
        console.log(res);
      });
  };

  //   const fileDownload=(e)=>{
  //    e.preventDefault()
  //    if (validateEmail(email) === true) {
  //    axios({
  //        url: `${downUrl}/${params.id}`,
  //        method: "GET",
  //        responseType: "blob"
  //    },{
  // //     headers: { "Content-Type": "application/json",
  // //     "Accept": "application/json",
  // // },
  //     // Always write this in order not to have CORS issues
  //     withCredentials: false,
  //     crossdomain: false,
  //     // mode: 'no-cors',
  //    }).then((res)=>{
  //       FileDownload(res.data, toolkit.toolkit_file)
  //       window.location = "/";
  //    })
  //    } else{
  //       console.log("Please input a valid email");
  //    }
  // }

  const getRecommend = async () => {
    const pageURL = recUrl;
    try {
      const response = await fetch(pageURL);
      const jsonData = await response.json();

      setRecommend(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  };
  useEffect(() => {
    getRecommend();
  }, []);

  // function onToken(token) {
  //   console.log(token.card.name);
  //   console.log(token.email);
  //   console.log(token.id);

  //   var email = token.email;
  //   var name = token.card.name;

  //   // alert(`Transaction Successful: ${token.email} `);

  //   try {
  //     const emailer = {
  //       email,
  //       name,
  //       tooltitle,
  //       toolprice,
  //     };
  //     const response = fetch(subUrl, {
  //       method: "POST",
  //       headers: { "Content-Type": "application/json" },
  //       body: JSON.stringify(emailer),
  //     });
  //     console.log(emailer);
  //     normalToast();
  //   } catch (err) {
  //     console.error(err.message);
  //   }
  // }

  const normalToast = () => {
    toast(`You've successfully purchased this toolkit: `, {
      type: "success",
    });
    countUpdator();
    // alert("Transaction Successful");
    setTimeout(() => {
      window.location.href = `${toolkit.toolkit_file}`;
      navigate("/thankyou");
    }, 6000);
  };

  async function handleToken(token, addresses) {
    const response = await axios.post(checkoutUrl, {
      token,
      tooltitle,
      toolprice,
    });

    // console.log(response.status);
    var email = token.email;
    var name = token.card.name;

    if (response.status === 200) {
      try {
        const emailer = {
          email,
          name,
          tooltitle,
          toolprice,
        };
        const response = fetch(subUrl, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(emailer),
        });
        // console.log(emailer);
        normalToast();
      } catch (err) {
        console.error(err.message);
      }
      // toast("Success! Check email for details", { type: "success" });
    } else {
      toast("Something went wrong", { type: "error" });
    }
  }

  return (
    <Fragment>
      <ToastContainer />
      <div className="container row">
        <div className="col-md-2">
          <Link to={"/"} className="text-link mt-5">
            <h5 className="card-title">
              <span className="material-icons keyar">arrow_left</span>BACK{" "}
            </h5>
          </Link>
        </div>

        <div className="col-sm-10 col-md-10 col-lg-10">
          <div className="row col-lg-12 cardiz">
            <div className="col-sm-12 col-md-12 col-lg-6 ">
              <div className="card cardiza">
                <img
                  className="preview-img"
                  src={`${imgUrl}/${toolkit.toolkit_img}`}
                  alt="Toolkit_Image"
                />
              </div>
              {/* <p className="vkit"></p> */}
            </div>
            <div className="col-sm-12 col-md-12 col-lg-5  offset-1 create">
              <h4 className="card-title"> {toolkit.title} </h4>
              <h5 className="card-title"> cost: ${toolkit.price}</h5>

              <div className={`${toolkit.price <= 0 ? "hide" : "show form"}`}>
                <StripeCheckout
                  name="Checkout"
                  description={toolkit.title}
                  label="Checkout"
                  // panelLabel="Yet Another Form"
                  // email="already-know-this@email.com"
                  amount={toolkit.price * 100}
                  currency="USD"
                  shippingAddress
                  billingAddress={false}
                  stripeKey={stripePubKey}
                  token={handleToken}
                  // opened={onOpened}
                >
                  <button
                    style={{
                      width: "100%",
                      backgroundColor: "#343434",
                      color: "#f2ea59",
                      padding: "5px",
                      height: "40px",
                      fontSize: "20px",
                      borderRadius: "0px",
                    }}
                    className="toolbtn"
                  >
                    Checkout
                  </button>
                </StripeCheckout>
              </div>
              <form className={`${toolkit.price <= 0 ? "show form" : "hide"}`}>
                {/* {toolkit.price <= 0 ? ( */}
                <>
                  <input
                    type="text"
                    name=""
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Full Name"
                    required
                  />
                  <input
                    type="email"
                    name=""
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="example@email.com"
                    required
                  />
                </>
                {/* ) : (
                  "hello"
                )} */}

                {!email && (
                  <button className="disabledbtn" disabled>
                    DOWNLOAD
                  </button>
                )}
                {/* {email && <button className="downloadbtn btn-success" onClick={(e) => { subscribe(e); fileDownload(e); }}>DOWNLOAD</button>} */}
                {email && (
                  <button
                    className="downloadbtn btn-success"
                    onClick={(e) => {
                      subscribe(e);

                      e.preventDefault();
                      if (validateEmail(email) === true) {
                        if (name === undefined) {
                          e.preventDefault();
                          alert("Please input Your Full Name ");
                        } else if (name.length === 0) {
                          e.preventDefault();
                          alert("Name cannot be Empty ");
                        } else {
                          // window.location.href = `${toolkit.toolkit_file}`;
                          // navigate("/thankyou");
                          normalToast();
                        }
                      }
                    }}
                  >
                    DOWNLOAD
                  </button>
                )}
              </form>
            </div>
          </div>

          <div className="row col-sm-12 cardio">
            <div className="col-sm-6">
              <div
                className="accordion accordion-flush"
                id="accordionFlushExample"
              >
                <div className="btn-group">
                  <button
                    className="btn btn-sm btn-default accordion-btn"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseOne"
                    aria-expanded="false"
                    aria-controls="flush-collapseOne"
                  >
                    DESCRIPTION
                    {/* <hr className="act"/> */}
                  </button>

                  <button
                    className="btn btn-sm btn-default accordion-btn"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseTwo"
                    aria-expanded="false"
                    aria-controls="flush-collapseTwo"
                  >
                    IMPORTANT INFO
                    {/* <hr className="act"/> */}
                  </button>
                </div>

                <div className="accordion-item">
                  <div
                    id="flush-collapseOne"
                    className="accordion-collapse collapse show"
                    aria-labelledby="flush-headingOne"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      {" "}
                      {toolkit.description}{" "}
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <div
                    id="flush-collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingTwo"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      {" "}
                      {toolkit.important_info}{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-4 offset-1"></div>
          </div>
        </div>
      </div>
      <div className="col-sm-10 offset-1 row mb-5 mt-5 reco">
        <div className="col-sm-4">
          {" "}
          <hr className="act" />{" "}
        </div>
        <div className="col-sm-4">
          <h3 className="text-center">RECOMMENDED TOOLKITS</h3>
        </div>
        <div className="col-sm-4">
          {" "}
          <hr className="act" />
        </div>
      </div>

      <div className="col-sm-11 offset-1 row ">
        {recommends.map((recommend) => (
          <div className="col-sm-3 cardozor" key={recommend.id}>
            <div className="card">
              <Link to={`/recommend/${recommend.id}`}>
                <img
                  className="preview-img"
                  src={`${imgUrl}/${recommend.toolkit_img}`}
                  alt="Toolkit_Image"
                />
              </Link>
            </div>
            <Link to={`/recommend/${recommend.id}`} className="text-link">
              <h5 className="card-title">{recommend.title}</h5>
            </Link>
          </div>
        ))}
      </div>
    </Fragment>
  );
};

export default ViewToolkit;
