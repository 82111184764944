import { Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";

const ThankYou = () => {
  let navigate = useNavigate();
  return (
    <Fragment>
      {/* <div className="text-center">
        <img
          className="img-responsive thanks-img"
          src="./thanks.jpg"
          alt="Thanks"
        />

        <div className="thanks mt-3" style={{ fontFamily: "Montserrat" }}>
          Thanks for downloading our "Toolkit" designed to help you organize
          your home, and <br />
          make important decisions on your workplace and career. <br />
          <button
            onClick={(e) => {
              window.location.href = "https://motherhonestly.com/membership/";
            }}
            style={{
              color: "royalblue",
              textDecoration: "none",
              fontFamily: "Montserrat",
              border: "none",
              backgroundColor: "transparent",
            }}
          >
            Become a Member
          </button>
        </div>

        <Link
          to={"/"}
          className="btn mt-5"
          style={{
            backgroundColor: "#f2ea59",
            fontFamily: "Montserrat",
            textDecoration: "none",
          }}
        >
          BACK TO TOOLKIT
        </Link>
      </div> */}

      <div className="container row thanksRow">
        <div className="col-sm-2"></div>
        <div className="col-sm-12 col-md-12 col-lg-10 row thanks">
          <div className="col-lg-6 col-md-6">
            <img
              className="w-100 thanks-img"
              src="https://motherhonestly.com/embed-js/thanks.jpg"
              alt="Thanks"
            />
          </div>

          <div className="col-lg-6 col-md-6">
            <h2 className="thanks-h1">Thanks for downloading our Toolkit.</h2>
            <p className="thanks-text">
              Our Toolkits are designed to help you organize your home, and make
              important decisions on your workplace and career.
            </p>

            <button
              onClick={(e) => {
                window.location.href = "https://motherhonestly.com/membership/";
              }}
              style={{
                color: "royalblue",
                textDecoration: "none",
                fontFamily: "Montserrat",
                border: "none",
                backgroundColor: "transparent",
                fontSize: "20px",
                cursor: "pointer",
              }}
            >
              Become a Member
            </button>

            <button
              className="mlk"
              onClick={(e) => {
                navigate("/");
              }}
              style={{
                color: "crimson",
                textDecoration: "none",
                fontFamily: "Montserrat",
                border: "none",
                backgroundColor: "transparent",
                fontSize: "20px",
                cursor: "pointer",
              }}
            >
              Back To Toolkit
            </button>
          </div>
        </div>
        {/* <div className="col-sm-1"></div> */}
      </div>
    </Fragment>
  );
};

export default ThankYou;
