import { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import Fade from 'react-reveal/Fade';

const Work = () => {
  const [toolkits, setToolkits] = useState([]);
  const [toolz, setToolz] = useState([]);

  var Url = `${process.env.REACT_APP_BASEURL_API}/work`;

  var imageUrl = `${process.env.REACT_APP_BASEURL_API}`;

  const getToolkits = async () => {
    const pageURL = Url;
    try {
      const response = await fetch(pageURL,
        {
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
          },
          // Always write this in order not to have CORS issues
          withCredentials: false,
          crossdomain: false,
          // mode: 'no-cors',
        });
      const jsonData = await response.json();
      setToolkits(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }
  useEffect(() => {
    getToolkits();
  }, [])

  const searchToolz = (event) => {
    setToolz(event.target.value);
  }
  // toolkits is the array of data collected
  // dataSearch is helping to filter the toolkits
  let dataSearch = toolkits.filter(item => {
    return Object.keys(item).some(key => item[key].toString().toLowerCase().includes(toolz.toString()
      .toLowerCase()
    ))
  });
  return (
    <Fragment>


      <div className="container mt-5">
        <div className="row toolz">
          <div className="col-sm-2 offset-1 order-first tkit"> <p>Toolkits </p> </div>

          {/* <div className="offset-7 col-sm-2 search">
             <input
                type="text"
                required
                name="title"
                placeholder="Search toolkits"
                value={toolz}
                onChange={searchToolz.bind(this)}
                 />
             </div> */}
        </div>
      </div>

      <div className="container mt-5">
        <div className="row cat">
          <div className="col-sm-2 offset-1 order-first cat">

            <div className="dropdown">
              <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                Sort Toolkits
              </button>
              <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <li><Link className="dropdown-item" to={"/"}>All</Link></li>
                <li><Link className="dropdown-item" to={"/childcare"}>Child Care</Link></li>
                <li><Link className="dropdown-item" to={"/household"}>Household</Link></li>
              </ul>
            </div>

          </div>


          <div className="col-sm-9 row">
            {dataSearch.map(toolkit => (

              <div className="col-sm-4 cardozo" key={toolkit.id}>

                <div className="card">
                  <Link to={`/view/${toolkit.id}`}>
                    <img className="preview-img" src={`${imageUrl}/toolkits/${toolkit.toolkit_img}`} alt="Toolkit_Image" />
                  </Link>
                </div>

                <Link to={`/view/${toolkit.id}`} className="text-link">
                  <h5 className="card-title">{toolkit.title} </h5>
                </Link>
              </div>
            ))}


          </div>
          <div className="foot"></div>

        </div>
      </div>




    </Fragment>
  );
}

export default Work;