import { Link } from "react-router-dom";

const PageError = () => {
    return (  
        
        <div className="page-error">

            <img className="mt-5 mb-3" src="../mh404.gif" alt="PageError" width="600" height="500"/>
            
            <h2>Sorry You Are Lost</h2>
            <Link to={'/'}>Go Back to Homepage</Link> 
            
           

        </div>
    );
}
 
export default PageError;